import { useState, useEffect } from 'preact/hooks';
import {formatDateTime,getInitials,getFormattedAmount} from '../../lib/utils';

const taskListSingleCardWizard = (props) => {
  let [followups, setFollowUps] = useState(props.followups);
  let [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    setFollowUps(props.followups);
  },[props.followups]);

  return (
    <div>
      {
        followups && followups.length > 0 && (
          <div class="workspace-cards fadeMoveUpAnimation">
            <div class="row justify-between align-center">
              <div class="display-flex">
                <span class="workspace-cardsnumber fs-16 f-w-500">
                  <div class="h-24px w-24px">
                    {props.stageIcon}
                  </div>
                </span>
                <span class="m-l-5 fs-14">TASKS</span>
              </div>
              <div class="display-flex">
                <button class=" custombutton-nextprev" disabled={currentIndex === 0 ?  true : false} onClick={(e)=> setCurrentIndex(currentIndex - 1)} >
                  <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" ><path d="M10 22 0 12 10 2 11.775 3.775 3.55 12 11.775 20.225Z" /></svg>
                </button>
                <span class="align-self-center">{ (currentIndex + 1) + "/" + followups.length }</span>
                <button class=" custombutton-nextprev" disabled={followups.length === currentIndex + 1 ?  true : false} onClick={(e)=> setCurrentIndex(currentIndex + 1)}>
                  <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M8.025 22 6.25 20.225 14.475 12 6.25 3.775 8.025 2 18.025 12Z" /></svg>
                </button>
              </div>
            </div>
            <div class="row m-t-10">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0" style="height:auto;overflow:auto;">
                <div>
                  <div class="flex-sb p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">Task Name</span>
                    </div>
                    <span class="fs-14 color-header-blue p-l-12 p-r-24 f-w-600 cardvalue-companyblue">{followups[currentIndex] && followups[currentIndex].displayName ? followups[currentIndex].displayName : '-'}</span>
                  </div>
                  <div class="flex-sb p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">Task Assignee</span>
                    </div>
                    <span class="fs-14 color-header-blue p-l-12 p-r-24 f-w-600 cardvalue-companyblue">{followups[currentIndex] && followups[currentIndex].assignedUserName ? followups[currentIndex].assignedUserName : '-'}</span>
                  </div>
                  <div class="flex-sb p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">Due Date</span>
                    </div>
                    <span class="fs-14 color-header-blue p-l-12 p-r-24 f-w-600 cardvalue-companyblue">{formatDateTime(followups[currentIndex].dueDate)}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </div>
  );
};

export default taskListSingleCardWizard;
