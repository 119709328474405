import { h, Component,Fragment } from 'preact';
import { useState, useEffect } from "preact/hooks";
import axios from "axios";
import { route } from "preact-router";
import { getItem, removeAll } from '../../lib/myStore';
import CONSTANTS from '../../lib/constants';
import { Home, Clipboard, Cases, Hyundai, Stock, Cart, Notification, Target, Analytics, Generate, Orgchart, Administration, InteractionSearchSuggestion, Upload, AccessControl, Tracker, Coupon, Ticket } from '../../components/svgs';
import io from 'socket.io-client';
/*
  Modification: Add Socket.io
  By: komal wable
  Date: 26/04/2023
*/
const LeftInfoPanel = (props) => {
  let userInfo = getItem('userinfo');
  // const socket = io(`${CONSTANTS.SOCKETAPI_URL}`);
  const { panelActive, showLeftPanel, setShowLeftPanel, setShowMobileFooter } = props;
  // const pathname = window.location.pathname;
  let [pathName, setPathName] = useState(window.location.pathname);
  let [showPanel, setShowPanel] = useState(false);
  let [showNotification, setShowNotification] = useState(false);

  // useEffect(async() => {
  //   socket.on('notification', async(response) => {
  //     if (response.assignedTo === userInfo.uuid || response.sendTo === userInfo.uuid) {
  //       setShowNotification(true);
  //     }
  //   });
  // }, [socket]);

  useEffect(() => {
    setPathName(window.location.pathname);
  },[window.location.pathname]);

  const logout = async (e) => {
    e.preventDefault();
    let res = await axios.post(`${CONSTANTS.API_URL}/api/v1/logout`);
    let email = getItem('userinfo').email;
    await axios.put(`${CONSTANTS.API_URL}/api/v1/user/pageVisitUserInformation`, { action: 'logout', typeOfVisit: 'success', email });
    if (res) {
      await removeAll();
      await route(`/`);
    }
  };

  return (
    <Fragment>
      <div class={`${showLeftPanel ? 'leftPanel-overlay' : 'display-none'}`}
        onClick={()=> setShowLeftPanel(false)}
      />
      <div class={`${showLeftPanel ? 'mobile-leftInfoPanel ' : 'mobile-leftInfoPanel1 ' } isMobile `}>
        <div title='Kothari Hyundai' class={'leftInfoPanel-menu-icon p-t-0 p-l-3'} onClick={() => {
          setPathName(`/workspace`);
          route(`/workspace`);}}
        >
          <div class="flex flex-m gap-8px logo p-l-10" >
            <Hyundai fill="black" height="32px" width="32px" fill={pathName === "/workspace" ? '#003984' : '#003984'} />

          </div>

        </div>
        <div title='Home' class={pathName === "/workspace" ? 'leftInfoPanel-menu-icon-active' : 'leftInfoPanel-menu-icon'} onClick={() => {
          setPathName(`/workspace`);
          route(`/workspace`);
          setShowLeftPanel(false);
          setShowMobileFooter(true);
        }}
        >
          <div class="flex flex-m gap-16px p-l-10">
            <Home fill="black" height="22px" width="22px" fill={pathName === "/workspace" ? 'black' : '#000000'} />
            <div class='menu-name flex-c-m'>Workspace</div>
          </div>
        </div>
        {/*<div title='Task' class={pathName === "/workspacedetails/dayPlanWorkspace" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon '} onClick={() => {
          route(`/workspacedetails/dayPlanWorkspace`);
          setPathName('/workspacedetails/dayPlanWorkspace');
          setShowLeftPanel(false);
          setShowMobileFooter(true);
        }}
        >
          <div class="flex flex-m gap-16px p-l-10">
            <Clipboard fill="black" height="22px" width="22px"fill={pathName === "/workspacedetails/dayPlanWorkspace" ? 'black' : '#000000'} />
            <div class='menu-name flex-c-m'>Tasks</div>
          </div>
        </div>*/}
        <div title='Case' class={pathName === "/workspaceCaseDetails" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'} onClick={() => {
          setPathName('/workspaceCaseDetails');
          route(`/workspaceCaseDetails`);
          setShowLeftPanel(false);
          setShowMobileFooter(true);
        }}
        >
          <div class="flex flex-m gap-16px p-l-10" style="margin-bottom: -5px;">
            <Cases fill="white" height="22px" width="22px" viewBox="0 0 302 382" fill={pathName === "/workspaceCaseDetails" ? 'black' : '#000000'} />
            <div class='menu-name flex-c-m'>Cases</div>
          </div>
        </div>
        <div title='Calls' class={pathName === "/workspacedetails/callsWorkspace" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'} onClick={() => {
          setPathName('/workspacedetails/callsWorkspace');
          route('/workspacedetails/callsWorkspace');
          setShowLeftPanel(false);
          setShowMobileFooter(true);
        }

        }
        >
          <div class="flex flex-m gap-16px p-l-10"  >
            <svg xmlns='http://www.w3.org/2000/svg' version='1.1' class='NavIcon SidebarTopNavLinks-typeIcon CheckNavIcon' viewBox='0 0 40 40' width='22' height='22'
              style="margin-bottom: 5px;"
            ><path d='M20,2.5C10.4,2.5,2.5,10.4,2.5,20S10.4,37.5,20,37.5S37.5,29.6,37.5,20S29.6,2.5,20,2.5z M20,34.5C12,34.5,5.5,28,5.5,20S12,5.5,20,5.5S34.5,12,34.5,20S28,34.5,20,34.5z M27.7,15c0.6,0.6,0.6,1.5,0,2.1l-10,10c-0.2,0.2-0.6,0.3-1,0.3c-0.4,0-0.8-0.1-1.1-0.4l-4.1-4.1c-0.6-0.6-0.6-1.5,0-2.1c0.6-0.6,1.5-0.6,2.1,0l3.1,3.1l8.9-8.9C26.2,14.4,27.1,14.4,27.7,15z' fill="white" /></svg>
            <div class='menu-name flex-c-m'>Calls</div>
          </div>
        </div>
        <div title='Inbox' class={pathName === "/workspacedetails/inboxWorkspace" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'} onClick={() => {
          setPathName('/workspacedetails/inboxWorkspace');
          route('/workspacedetails/inboxWorkspace');
          setShowLeftPanel(false);
          setShowMobileFooter(true);
        }
        }
        >
          <div class="flex flex-m gap-16px p-l-10"  >
            <svg xmlns="http://www.w3.org/2000/svg" style="margin-bottom: 5px;" height="22"  width="22" fill="black"><path d="M4 20Q3.175 20 2.588 19.413Q2 18.825 2 18V6Q2 5.175 2.588 4.588Q3.175 4 4 4H20Q20.825 4 21.413 4.588Q22 5.175 22 6V18Q22 18.825 21.413 19.413Q20.825 20 20 20ZM12 13 4 8V18Q4 18 4 18Q4 18 4 18H20Q20 18 20 18Q20 18 20 18V8ZM12 11 20 6H4ZM4 8V6V8V18Q4 18 4 18Q4 18 4 18Q4 18 4 18Q4 18 4 18Z" /></svg>
            <div class='menu-name flex-c-m'>Inbox</div>
          </div>
        </div>
        <div title="Click to log out" class=" leftInfoPanel-logout cursor-pointer w-24px h-24px flex flex-m gap-16px p-l-10" onClick={(e) => logout(e)}>
          <em class="cursor-pointer icon icon-sign-out w-22px h-22px text-black fs-24" />
          <div class='menu-name flex-c-m'>Logout</div>
        </div>
      </div>
      {/*
        Modification: Applied Inline-style to leftPanel desktop container
        Date: Jan-17-22
        By: Vipin
        */}
      <div style="background-color: #002c5f;border-right: 2px solid #002c5f;" class="leftInfoPanel-tab p-t-4 isDesktop">
        <div title='Kothari Hyundai' class={'leftInfoPanel-menu-icon p-t-0'} onClick={() => {
          setPathName(`/workspace`);
          route(`/workspace`);}}
        >
          <div>
            <Hyundai fill="white" height="32px" width="32px" fill={pathName === "/workspace" ? 'white' : 'white'} />
          </div>
        </div>
        <div title='Home' class={pathName === "/workspace" ? 'leftInfoPanel-menu-icon-active' : 'leftInfoPanel-menu-icon'} onClick={() => {
          setPathName(`/workspace`);
          route(`/workspace`);}}
        >
          <div>
            <Home fill="white" height="22px" width="22px" fill={pathName === "/workspace" ? 'black' : 'white'} />
            <div class='menu-name flex-c-m'>Home</div>
          </div>
        </div>
        {/*
          userInfo && !userInfo.isSiloAdmin && (
            <div title='Task' class={pathName === "/workspacedetails/dayPlanWorkspace" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon '} onClick={() => {
              route(`/workspacedetails/dayPlanWorkspace`);
              setPathName('/workspacedetails/dayPlanWorkspace');
            }}
            >
              <div>
                <Clipboard fill="white" height="22px" width="22px"fill={pathName === "/workspacedetails/dayPlanWorkspace" ? 'black' : 'white'} />
              </div>
              <div class='menu-name flex-c-m'>Tasks</div>
            </div>
          )
        */}
        {
          userInfo && !userInfo.isSiloAdmin && (
            <div title='Case' class={pathName === "/workspaceCaseDetails" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'} onClick={() => {
              setPathName('/workspaceCaseDetails');
              route(`/workspaceCaseDetails`);}}
            >
              <div style="margin-bottom: -5px;">
                <Cases fill="white" height="22px" width="22px" viewBox="0 0 302 382" fill={pathName === "/workspaceCaseDetails" ? 'black' : 'white'} />
              </div>
              <div class='menu-name flex-c-m'>Cases</div>
            </div>
          )
        }
        {
          userInfo && !userInfo.isSiloAdmin && !['insurance team lead', 'insurance head', 'insurance agent'].includes(userInfo.userDesignation.toLowerCase()) && (
            <div title='View Targets' class={pathName === "/caseCommitment" ? 'leftInfoPanel-menu-icon-active' : 'leftInfoPanel-menu-icon'}>
              <div style="margin-bottom: -5px;">
                <Target fill={pathName === "/caseCommitment" ? 'black' : 'white'} viewBox="0 0 512 512" height="22px" width="22px" action={(e)=> { setPathName('/caseCommitment'); route('/caseCommitment'); }} />
              </div>
              <div class='menu-name flex-c-m'>Targets</div>
            </div>
          )
        }
        {/*
          userInfo && userInfo.isSiloAdmin && (
            <div title='View Tickets' class={pathName === "/ticket/list" ? 'leftInfoPanel-menu-icon-active' : 'leftInfoPanel-menu-icon'}>
              <div style="margin-bottom: -5px;">
                <Ticket fill={pathName === "/ticket/list" ? 'black' : 'white'} viewBox="0 0 512 512" height="22px" width="22px" action={(e)=> { setPathName('/ticket/list'); route('/ticket/list'); }} />
              </div>
              <div class='menu-name flex-c-m'>Tickets</div>
            </div>
          )
        */}
        {/*
          Modification: change title
          Date: 03/02/2023
          By: Komal
          */}
        {
          userInfo && !userInfo.isSiloAdmin && userInfo.userDesignation && userInfo.userDesignation === "SALES CO-ORDINATOR" && (
            <div title='Stock' class={pathName === "/stockDashboard" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'} onClick={() => {
              setPathName('/stockDashboard');
              route(`/stockDashboard`);}}
            >
              <div style="margin-bottom: -5px;">
                <Stock fill="white" height="22px" width="22px" viewBox="0 0 302 382" fill={pathName === "/stockDashboard" ? 'black' : 'white'} />
              </div>
              <div class='menu-name flex-c-m'>Stock</div>
            </div>
          )
        }
        {
          userInfo && !userInfo.isSiloAdmin && userInfo.userDesignation && userInfo.userDesignation === "SALES CO-ORDINATOR" && (
            <div title='Orders' class={pathName === "/orderDashboard" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'} onClick={() => {
              setPathName('/orderDashboard');
              route(`/orderDashboard`);}}
            >
              <div style="margin-bottom: -5px;">
                <Cart fill="white" height="24px" width="24px"  fill={pathName === "/orderDashboard" ? 'black' : 'white'} />
              </div>
              <div class='menu-name flex-c-m'>Orders</div>
            </div>
          )
        }
        {
          userInfo && !userInfo.isSiloAdmin && userInfo.roleName && userInfo.roleName !== "EXECUTIVE" && (
            <div title='Push to NDMS' class={pathName === "/jobWorkspace" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'} onClick={() => {
              setPathName('/jobWorkspace');
              route(`/jobWorkspace`);
            }}
            >
              <div style="margin-bottom: -5px;">
                <Upload height="22px" width="22px" fill={pathName === "/jobWorkspace" ? '#06141f' : '#fff'} />
              </div>
              <div class='menu-name flex-c-m'>NDMS</div>
            </div>
          )
        }
        {
          userInfo && !userInfo.isSiloAdmin && (
            <div title='Analytics' class={pathName === "/analytics" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'} onClick={() => {
              setPathName('/analytics');
              route(`/analytics`);
            }}
            >
              <div style="margin-bottom: -5px;">
                <Analytics height="22px" width="22px" stroke={pathName === "/analytics" ? '#06141f' : '#fff'} />
              </div>
              <div class='menu-name flex-c-m'>Analytics</div>
            </div>
          )
        }
        {
          userInfo && userInfo.isSiloAdmin && (
            <div title='Project Tracker' class={pathName === "/projectTracker" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'} onClick={() => {
              setPathName('/projectTracker');
              route(`/projectTracker`);
            }}
            >
              <div style="margin-bottom: -5px;">
                <Tracker height="22px" width="22px" fill={pathName === "/projectTracker" ? '#06141f' : '#fff'} />
              </div>
              <div class='menu-name flex-c-m'>Project Tracker</div>
            </div>
          )
        }
        {
          userInfo && !userInfo.isSiloAdmin && showNotification ?
            <div title='Notifications' class={pathName === "/notificationDashboard" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'} onClick={() => {
              setShowNotification(false);
              setPathName('/notificationDashboard');
              route(`/notificationDashboard`);}}
            >
              <div style="margin-bottom: -5px;">
                <span class="bell-notification fa fa-bell" />
              </div>
              <div class='menu-name pos-relative bottom-5px'>Alerts</div>
            </div> :
            <div title='Notifications' class={pathName === "/notificationDashboard" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'} onClick={() => {
              setPathName('/notificationDashboard');
              route(`/notificationDashboard`);}}
            >
              <div style="margin-bottom: -5px;">
                <Notification stroke="white" height="24px" width="24px" stroke={pathName === "/notificationDashboard" ? 'black' : 'white'} />
              </div>
              <div class='menu-name flex-c-m'>Alerts</div>
            </div>
        }
        {/*
          Bug: T1823 - BG_FN: Insurance List View: My Approval and Aprroval Search is not working
          Modification: Commented my approval details page as it is same as workspaceApprovalDetails as discussed by rutuja
          By: Devang
          Date: 04/05/2024
        */}
        {/*(userInfo.userDesignation && (userInfo.userDesignation.toLowerCase() !== "administrator" && userInfo.userDesignation.toLowerCase() !== "business analyst")) && (
          <div
            title='My Approvals'
            class={pathName === "/workspaceMyApprovalDetails" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'}
            onClick={() => {
              setPathName('/workspaceMyApprovalDetails');
              route(`/workspaceMyApprovalDetails`);
            }}
          >
            <div style="margin-bottom: -5px;">

              <Clipboard height="22px" width="22px" fill={pathName === "/workspaceMyApprovalDetails" ? '#06141f' : '#fff'} />
            </div>

            <div class='menu-name flex-c-m'>My Approv.</div>
          </div>
        )*/}
        {/*
          Bug: Feature - Info Modal Value Change Approval
          Modification: Added Approval component to route to approval page
          By: Devang
          Date: 22/05/2023
        */}
        {/*
          Bug: T1823 - BG_FN: Insurance List View: My Approval and Aprroval Search is not working.
          Modification: Added restriction for insurance TL designation
          By: Devang
          Date: 05/05/2025
        */}
        {
          userInfo && !userInfo.isSiloAdmin && userInfo.userDesignation === "Insurance Team Lead" &&
          <div title='Approval' class={pathName === "/workspaceApprovalDetails" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'} onClick={() => {
            setPathName('/workspaceApprovalDetails');
            route(`/workspaceApprovalDetails`);}}
          >
            <div style="margin-bottom: -5px;">
              <Clipboard fill="black" height="22px" width="22px" fill={pathName === "/workspaceApprovalDetails" ? 'black' : 'white'} />
            </div>
            <div class='menu-name flex-c-m'>Approv.</div>
          </div>
        }
        {
          userInfo && userInfo.isSiloAdmin && (
            <div title='Scheduler Dashboard' class={pathName === "/schedulerDashboard" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'} onClick={() => {
              setPathName('/schedulerDashboard');
              route(`/schedulerDashboard`);
            }}
            >
              <div style="margin-bottom: -5px;">
                <Generate height="22px" width="22px" fill={pathName === "/schedulerDashboard" ? '#06141f' : '#fff'} />
              </div>
              <div class='menu-name flex-c-m'>Scheduler</div>
            </div>
          )
        }
        {
          userInfo && userInfo.isSiloAdmin && (
            <div title='Administration' class={pathName === "/siloAdministration" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'} onClick={() => {
              setPathName('/siloAdministration');
              route(`/siloAdministration`);
            }}
            >
              <div style="margin-bottom: -5px;">
                <Administration height="22px" width="22px" fill={pathName === "/siloAdministration" ? '#06141f' : '#fff'} />
              </div>
              <div class='menu-name flex-c-m'>Admin</div>
            </div>
          )
        }
        {
          userInfo && userInfo.isSiloAdmin && (
            <div title='Organization' class={pathName === "/organization" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'} onClick={() => {
              setPathName('/organization');
              route(`/organization`);
            }}
            >
              <div style="margin-bottom: -5px;">
                <Orgchart height="22px" width="22px" fill={pathName === "/organization" ? '#06141f' : '#fff'} />
              </div>
              <div class='menu-name flex-c-m'>Org</div>
            </div>
          )
        }
        {
          userInfo && userInfo.isSiloAdmin && (
            <div title='>Config Manager' class={pathName === "/processMapping" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'} onClick={() => {
              setPathName('/processMapping');
              route(`/processMapping`);
            }}
            >
              <div style="margin-bottom: -5px;">
                <InteractionSearchSuggestion height="22px" width="22px" stroke={pathName === "/processMapping" ? '#06141f' : '#fff'} />
              </div>
              <div class='menu-name flex-c-m'>Config</div>
            </div>
          )
        }
        {
          userInfo && userInfo.isSiloAdmin && (
            <div title='Access Control' class={pathName === "/aclPermissions" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'} onClick={() => {
              setPathName('/aclPermissions');
              route(`/aclPermissions`);
            }}
            >
              <div style="margin-bottom: -5px;">
                <AccessControl height="22px" width="22px" fill={pathName === "/aclPermissions" ? '#06141f' : '#fff'} />
              </div>
              <div class='menu-name flex-c-m'>ACL</div>
            </div>
          )
        }
        {(userInfo.userDesignation && (userInfo.userDesignation.toLowerCase() === "insurance head" || userInfo.userDesignation.toLowerCase() === "service accounts executive")) && (
          <div
            title='Coupons'
            class={pathName === "/workspaceCouponDetails" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'}
            onClick={() => {
              setPathName('/workspaceCouponDetails');
              route(`/workspaceCouponDetails`);
            }}
          >
            <div style="margin-bottom: -5px;">
              <Coupon height="22px" width="22px" stroke={pathName === "/workspaceCouponDetails" ? '#06141f' : '#fff'} />
            </div>
            <div class='menu-name flex-c-m'>Coupons</div>
          </div>
        )}
        {(userInfo.userDesignation && userInfo.userDesignation.toLowerCase() === "insurance head") && (
          <div
            title='Case Allocation'
            class={pathName === "/caseManagement" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'}
            onClick={() => {
              setPathName('/caseManagement');
              route(`/caseManagement`);
            }}
          >
            <div style="margin-bottom: -5px;">
              <InteractionSearchSuggestion height="22px" width="22px" viewBox="0 0 18 18" stroke={pathName === "/caseManagement" ? '#06141f' : '#fff'} />
            </div>
            <div class='menu-name flex-c-m'>Allocation</div>
          </div>
        )}
        <div title="Click to log out" class=" leftInfoPanel-logout cursor-pointer m-l-5 w-24px h-24px" onClick={(e) => logout(e)}>
          <em fill="white" class="cursor-pointer icon icon-sign-out w-24px h-24px text-white fs-24" />
        </div>
      </div>
    </Fragment>
  );
};

export default LeftInfoPanel;
