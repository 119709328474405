import { h, Component, Fragment } from 'preact';
import { useState, useEffect } from "preact/hooks";

import toastr from 'toastr';
import html2canvas from 'html2canvas';
import axios from 'axios';
import imageCompression from 'browser-image-compression';

import { callAPI, reportException } from '../../lib/transceiver';
import { deepCopy, isAllowedFileType } from '../../lib/utils';

import { Screenshot, Loop } from '../../components/svgs';
import CreateEditForm from '../../components/createEditForm';

// import { useDispatch } from "react-redux";
// import { getDashboardTicketData } from "../../store/ticketing/action";

const TicketGeneration = (props) => {
  const { setIsTicketListUpdated } = props;
  const fileName = 'ticketGeneration';

  // const dispatch = useDispatch();
  const [dynamicProps, setDynamicProps] = useState();
  const [isCreateEditFormPopover, setIsCreateEditFormPopover] = useState(false);
  const [formEntityObject, setFormEntityObject] = useState({});
  const [errorField, setErrorField] = useState([]);
  const [isScreenshotInProgress, setIsScreenshotInProgress] = useState(false);
  // const [backspacePressCount, setBackspacePressCount] = useState(0);
  // const [tags, setTags] = useState([]);
  const [isTicketInCreation, setIsTicketInCreation] = useState(false);

  useEffect(() => {
    if (!isCreateEditFormPopover) {
      setFormEntityObject({});
      // setTags([]);
    }
  }, [isCreateEditFormPopover]);
  // const [snackbarInfo, setsnackbarInfo] = useState({ open: false, message: '', severity: 'success' });

   // let [screenshotCaptured, setScreenshotCaptured] = useState(false);

   function triggerScreenFlash(){
     const body = document.body;
     const flashDiv = document.createElement('div');
     flashDiv.classList.add('flash');
     body.appendChild(flashDiv);
     // Force a reflow to apply the CSS change immediately
     void flashDiv.offsetWidth;
     flashDiv.style.opacity = '0.5';
     // Listen for the end of the CSS transition
     flashDiv.addEventListener('transitionend', function() {
       body.removeChild(flashDiv);
     });
   }

   const takeScreenshot = async () => {
     let body = document.body;
     await html2canvas(body).then(async (canvas) => {
       await triggerScreenFlash();
       toastr.success('Screenshot Captured'); // screen captured toaster
       // setScreenshotCaptured(true);

       let dataURL = canvas.toDataURL('image/jpeg');
       const base64Data = dataURL.replace(/^data:image\/\w+;base64,/, "");
       const arrayBuffer = Uint8Array.from(atob(base64Data), c => c.charCodeAt(0));

       await uploadScreenshotFile(arrayBuffer);
     });
   }

   /*const handleClose = (event, reason) => {
     if (reason === 'clickaway') {
       return;
     }
     setScreenshotCaptured(false);
   };*/

 //Commented Code is not needed to upload screenshot directly but it is a part of this function and might need at a later point
   async function uploadScreenshotFile(arrayBuffer) {
     const file = {
       name: 'screenshot.jpeg',
       size: arrayBuffer.byteLength,
       type: 'image/jpeg',
       value: 'screenshot.jpeg',
     };

     try {
       let payload = {
         file,
         // vendorKey: 'screenshot',
         interactionID: 'entity'
       };
       let fileDetails;
       await callAPI(`post`, `/v1/file/getSignedUrl`, payload).then(async (res) => {
         if (res && res.data) {
           fileDetails = res.data;
           try {
             //  Save File on S3
             const opts = {
               headers: {
                 name: 'Content-Type',
                 value: 'multipart/form-data'
               }
             };
             let fileData = arrayBuffer;
             await axios.put(fileDetails.signedURL, fileData, opts);

             let obj = deepCopy(formEntityObject);
             obj['capturedScreenshot'] = fileDetails.uuid;
             setFormEntityObject(obj);
           } catch (HTTPException) {
             await reportException({
               HTTPException,
               fileName,
               functionName: 'uploadScreenshotFile'
             });
           }
         }
       });
     } catch (HTTPException) {
       await reportException({
         HTTPException,
         fileName,
         functionName: 'uploadScreenshotFile'
       });
     }
   }

  const takeScreenshotAndCreateTicket = async () => {
    //getting dynamic properties
    try {
      await takeScreenshot();
      const response = await callAPI(`get`, `/v1/entityDynamicProperty`, `formName=Ticket&activationStatus=active`);
      if (response.data) {
        setDynamicProps(response.data);
        setIsCreateEditFormPopover(true);
      }
      setIsScreenshotInProgress(false);
    } catch (HTTPException) {
      setIsScreenshotInProgress(false);
      await reportException({
        HTTPException,
        fileName,
        functionName: 'takeScreenshotAndCreateTicket'
      });
    }
  };

  const setFormValueInput = async (e, fieldName) => {
    // input values
    e.preventDefault();
    let obj = deepCopy(formEntityObject);

    if (!e.target.value) {
      obj[fieldName] = e.target.value;
      let successNode = document.getElementById('success-' + fieldName);
      if (successNode) {
        successNode.style.display = 'none';
      }
    } else {
      obj[fieldName] = e.target.value;
    }
    setFormEntityObject(obj);
  };

  const inputClicked = async (e) => {
    e.preventDefault();
    let textBox = document.getElementById(e.target.id);
    if (textBox) {
      await textBox.focus();
    }
  };

  const saveFormInput = async (e, fieldName, formType) => {
    e.preventDefault();

    let obj = deepCopy(formEntityObject);
    if (formType === 'multipleSelect') {
      if (!obj[fieldName]) {
        obj[fieldName] = [];
      }
      let flag = 0;
      obj[fieldName] &&
        obj[fieldName].map((key, index) => {
          if (key === e.target.value) {
            obj[fieldName].splice(index, 1);
            flag++;
          }
        });
      if (flag === 0) {
        obj[fieldName].push(e.target.value);
      }
    } else if (formType && formType === 'select') {
      obj[fieldName] = e.target.value;
    } else {
      obj[fieldName] = e.target.value;
    }
    setFormEntityObject(obj);
  };

  const setFormTagArray = (payload, dynamicProp) => {
    let obj = deepCopy(formEntityObject);
    obj[dynamicProp.name] = payload;
    setFormEntityObject(obj);
  };

  async function getCompressedImageFile(fileToBeUploaded) {
    let imageFileToBeCompressed = fileToBeUploaded.files[0];
    const options = {
      maxSizeMB: 0.2,
      maxWidthOrHeight: 900
    };
    let compressedFile = await imageCompression(imageFileToBeCompressed, options);
    let fileData = '';
    let fileObj = {};
    if (fileToBeUploaded.files[0].size > 30000) {
      fileData = compressedFile;
      fileObj.name= compressedFile.name;
      fileObj.size= compressedFile.size;
      fileObj.type= imageFileToBeCompressed.type;
      fileObj.value= fileToBeUploaded.value;
    } else {
      fileData = imageFileToBeCompressed;
      fileObj.name= imageFileToBeCompressed.name;
      fileObj.size= imageFileToBeCompressed.size;
      fileObj.type= imageFileToBeCompressed.type;
      fileObj.value= fileToBeUploaded.value;
    }
    return Promise.resolve(fileData);
  }

  const uploadFile = async (e, fieldName) => {
    try {
      const fileTypeError = await isAllowedFileType(e.target.files[0], ['png', 'jpg', 'jpeg']);
      if (!fileTypeError) {
        toastr.warning("This file cannot be uploaded either because this is not a supported file type. Please exclude such files and try again.");
        return;
      }
      const file = {
        name: e.target.files[0].name,
        size: e.target.files[0].size,
        type: e.target.files[0].type,
        value: e.target.files[0].name,
      };
      let payload = {
        file,
        interactionID: 'entity'
      }
        let fileDetails
        await callAPI(`post`, `/v1/file/getSignedUrl`, payload).then(async (res) => {
          if (res && res.data) {
            fileDetails = res.data;
            try {
              //  Save File on S3
              const opts = {
                headers: {
                  name: 'Content-Type',
                  value: 'multipart/form-data',
                }
              };
              let fileData = e.target.files[0];
              let fileExtension = e.target.files[0].name.split('.').pop();
              fileData = await getCompressedImageFile(e.target);
              const fileUpload = await axios.put(fileDetails.signedURL, fileData, opts);

              let obj = deepCopy(formEntityObject);
              obj['capturedScreenshot'] = fileDetails.uuid;
              setFormEntityObject(obj);
            } catch (e) {
              console.error(e);
            }
          }
        });
    } catch (HTTPException) {
      await reportException({
        HTTPException,
        fileName,
        functionName: 'uploadFile'
      });
    }
  }

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const toggleFormPopover = (e, isIgnoreScreenshotDeletion) => {
    // form functions
    if (!isIgnoreScreenshotDeletion && !isTicketInCreation) {
      callAPI(`put`, `/v1/file/remove/${formEntityObject['capturedScreenshot']}`, {
        interactionID: 'entity'
      });
    }
    setIsCreateEditFormPopover(!isCreateEditFormPopover);
    setErrorField([]);
  };

  const resetFormValues = () => {
    // reset state values after form submitted
    setFormEntityObject({});
    setDynamicProps();
    setErrorField([]);
  };

  const submitForm = async (e) => {
    //On submit event base on edit or create
    e.preventDefault();
    if (isCreateEditFormPopover) {
      try {
        let payload = {};
        let errorFieldsObj = [];
        // setting payload
        dynamicProps.length &&
          dynamicProps.map((dynamicProp) => {
            payload[dynamicProp.name] = formEntityObject[dynamicProp.name];
          });

        //setting error field
        if (dynamicProps.length) {
          await Promise.all(dynamicProps.map((dynamicProp) => {
            if ( dynamicProp.isRequired && dynamicProp.name && (payload[dynamicProp.name] === undefined || payload[dynamicProp.name] === '')) {
              if (!dynamicProp.dependentProp && (!dynamicProp.dependentPropValue || !dynamicProp.dependentPropValue.length)) {
                errorFieldsObj.push(dynamicProp);
                setErrorField(errorFieldsObj);
              } else if (dynamicProp.dependentPropValue === payload[dynamicProp.dependentProp]) {
                if ( dynamicProp.isRequired && dynamicProp.name && (payload[dynamicProp.name] === undefined || payload[dynamicProp.name] === '')) {
                  errorFieldsObj.push(dynamicProp);
                  setErrorField(errorFieldsObj);
                }
              }
            }
          }));

        }

        if (!errorFieldsObj.length) {
          await createNewTicket(payload);
          // setsnackbarInfo({ open: true, message: 'Ticket created successfully', severity: 'success' });
        } else {
          // setsnackbarInfo({ open: true, message: 'Please fill out all required fields', severity: 'error' });
          toastr.error('Please fill out all required fields');
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  /*function closeSnackbar() {
    setsnackbarInfo((prevData) => {
      return { ...prevData, open: false };
    });
  }*/

  const createNewTicket = async (payload) => {
    try {
      setIsTicketInCreation(true);
      await callAPI(`post`, `/v1/ticket`, payload);
      toastr.success('Ticket created successfully');

      toggleFormPopover(null, true);
      resetFormValues();
      setIsTicketListUpdated(e => !e);
      setIsTicketInCreation(false);
      // await dispatch(getDashboardTicketData());
      // await dispatch(getTicketStatusData());
    } catch (HTTPException) {
      setIsTicketInCreation(false);
      toastr.error('Something went wrong');
      await reportException({
        HTTPException,
        fileName,
        functionName: 'createNewTicket'
      });
    }
  };

  async function addTag(e, dynamicProp) {
    /*if (e.target.value) {
      setBackspacePressCount(0);
    }
    if (e.keyCode === 8 && !e.target.value && backspacePressCount < 2) {
      setBackspacePressCount(backspacePressCount + 1);
    }*/
    setDynamicProps((prevData) => {
      return prevData.map((prevDynamicProp, i) => ({
        ...prevDynamicProp,
        tempValue: prevDynamicProp.uuid === dynamicProp.uuid ? e.target.value : prevDynamicProp.tempValue,
      }));
    });

    if (e.keyCode === 8 && !dynamicProp.tempValue && formEntityObject[dynamicProp.name] && formEntityObject[dynamicProp.name].length) {
      removeTag(formEntityObject[dynamicProp.name].length-1, dynamicProp);
      return;
    }

    if (e.keyCode === 13) {
      let tag = e.target.value.trim();

      if (!tag || (tag && formEntityObject[dynamicProp.name] && formEntityObject[dynamicProp.name].includes(tag))) {
        return;
      }

      // setTags([...tags, tag]);

      let payload = [...(formEntityObject[dynamicProp.name] || []), tag];

      // e.target.value = '';
      setDynamicProps((prevData) => {
        return prevData.map((prevDynamicProp, i) => ({
          ...prevDynamicProp,
          tempValue: prevDynamicProp.uuid === dynamicProp.uuid ? '' : prevDynamicProp.tempValue,
        }));
      });
      // tag = '';
      // setBackspacePressCount(0);
      setFormTagArray(payload, dynamicProp);
    }
  }

  function removeTag(index, dynamicProp) {
    let updatedTags = [...formEntityObject[dynamicProp.name]];
    updatedTags.splice(index, 1);
    let payload = updatedTags;
    setFormTagArray(updatedTags, dynamicProp);
  }

  async function deleteUploadedFile(e, fieldName) {
    // deleting uploaded image
    let obj = deepCopy(formEntityObject);
    obj[fieldName] = '';
    setFormEntityObject(obj);
  }

  return (
    <Fragment>
      <div class="flex-c flip-card-inner m-r-10 h-fit-content">
        <div class={`leftInfoPanel-cards p-8-imp h-fit-content cursor-pointer flex-c-m`}>
          <span>
            {
              isScreenshotInProgress ?
              <Loop height="24px" width="24px" stroke="#fff" classes="rotating" title='Screenshot in progress'/>
              :
              <Screenshot height="24px" width="24px" fill="#fff" action={() => {
                setIsScreenshotInProgress(true);
                takeScreenshotAndCreateTicket();
              }} title='Take screenshot & create ticket'/>
            }
          </span>
        </div>
      </div>
      {
        isCreateEditFormPopover &&
        <CreateEditForm
          operation={'Create'}
          toggleFormPopover={toggleFormPopover}
          inputClicked={inputClicked}
          backgroundColor={'#f7f7f7'}
          dynamicProps={dynamicProps}
          entityObject={formEntityObject}
          saveFormInput={saveFormInput}
          setFormValueInput={setFormValueInput}
          uploadFile={uploadFile}
          // setFormTagArray={setFormTagArray}
          addTag={addTag}
          removeTag={removeTag}
          errorField={errorField}
          submitForm={submitForm}
          entityName="Ticket"
          deleteUploadedFile={deleteUploadedFile}
        />
      }
    </Fragment>
  );
}

export default TicketGeneration;
