import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon
const Endorsement = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg  onclick={(e) => props.action && props.action(e)} xmlns="http://www.w3.org/2000/svg" version="1.0" class={`${props.classes || ""}`} width={props.width || "512pt"} height={props.height || "512pt"}
  	viewBox={props.viewBox || "0 0 512 512"} preserveAspectRatio="xMidYMid meet"
      >
        <g xmlns="http://www.w3.org/2000/svg" transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || "#000000"} stroke="none">
          <path d="M372 4857 l-22 -23 0 -1150 0 -1151 23 -21 23 -22 890 0 891 0 21 23 22 23 0 898 0 898 -277 274 -278 274 -636 0 -636 0 -21 -23z m1208 -347 c0 -217 1 -231 20 -250 19 -19 33 -20 250 -20 l230 0 0 -805 0 -805 -795 0 -795 0 0 1055 0 1055 545 0 545 0 0 -230z m270 -5 l125 -125 -128 0 -127 0 0 125 c0 69 1 125 3 125 1 0 58 -56 127 -125z"/>
          <path d="M685 3915 c-30 -30 -31 -54 -4 -89 l20 -26 584 0 584 0 21 27 c25 32 20 69 -12 96 -19 16 -62 17 -595 17 l-574 0 -24 -25z"/>
          <path d="M686 3484 c-43 -42 -22 -108 36 -119 18 -3 284 -5 590 -3 605 3 579 1 593 58 4 18 0 34 -15 53 l-21 27 -584 0 c-517 0 -585 -2 -599 -16z"/>
          <path d="M685 3045 c-30 -30 -31 -54 -4 -89 l20 -26 584 0 584 0 21 27 c25 32 20 69 -12 96 -19 16 -62 17 -595 17 l-574 0 -24 -25z"/>
          <path d="M2910 4654 c-14 -2 -52 -9 -85 -15 -85 -14 -177 -45 -272 -91 -97 -47 -116 -74 -88 -129 42 -81 137 -237 155 -254 23 -21 36 -18 150 29 181 75 359 72 535 -9 184 -84 314 -242 365 -441 11 -42 20 -81 20 -86 0 -4 -42 -8 -94 -8 -91 0 -95 -1 -116 -27 -13 -17 -19 -35 -16 -49 8 -32 375 -666 394 -681 25 -20 66 -15 89 10 23 25 358 602 378 651 14 34 11 51 -13 79 -12 14 -32 17 -112 17 l-97 0 -6 60 c-28 268 -182 543 -400 716 -181 143 -400 222 -632 229 -71 2 -141 2 -155 -1z m345 -162 c189 -53 328 -134 449 -262 83 -88 119 -141 171 -253 55 -119 75 -196 84 -324 9 -129 15 -136 111 -143 l70 -5 -117 -202 c-64 -112 -120 -203 -124 -203 -6 0 -225 371 -237 402 -2 4 26 8 62 8 70 0 105 19 107 58 2 47 -4 114 -17 178 -92 454 -569 742 -1007 608 -134 -41 -121 -42 -155 18 l-31 53 22 12 c37 21 145 55 220 69 98 19 299 11 392 -14z"/>
          <path d="M2922 2607 l-22 -23 0 -1150 0 -1151 23 -21 23 -22 890 0 891 0 21 23 22 23 0 900 0 899 -273 273 -272 272 -641 0 -641 0 -21 -23z m1208 -344 c0 -300 -27 -273 272 -273 l228 0 0 -805 0 -805 -795 0 -795 0 0 1055 0 1055 545 0 545 0 0 -227z m270 -8 l125 -125 -128 0 -127 0 0 125 c0 69 1 125 3 125 1 0 58 -56 127 -125z"/>
          <path d="M3242 1673 c-32 -27 -37 -64 -12 -96 l21 -27 584 0 584 0 20 26 c27 35 26 59 -4 89 l-24 25 -574 0 c-533 0 -576 -1 -595 -17z"/>
          <path d="M3230 1223 c-15 -19 -19 -35 -15 -53 14 -57 -12 -55 593 -58 306 -2 572 0 590 3 58 11 79 77 36 119 -14 14 -82 16 -599 16 l-584 0 -21 -27z"/>
          <path d="M3242 803 c-32 -27 -37 -64 -12 -96 l21 -27 584 0 584 0 20 26 c27 35 26 59 -4 89 l-24 25 -574 0 c-533 0 -576 -1 -595 -17z"/>
          <path d="M1171 2223 c-35 -30 -391 -658 -391 -691 0 -50 25 -62 131 -62 l94 0 17 -103 c45 -279 190 -522 410 -688 330 -248 761 -289 1132 -108 74 36 101 66 92 101 -12 48 -151 272 -178 287 -25 14 -31 13 -105 -22 -191 -90 -392 -89 -578 2 -141 69 -235 162 -304 298 -33 64 -75 209 -65 224 3 5 45 9 94 9 67 0 92 4 104 16 40 39 35 49 -164 396 -106 183 -199 339 -208 346 -22 17 -56 15 -81 -5z m163 -404 c64 -110 116 -203 116 -205 0 -2 -26 -4 -59 -4 -45 0 -65 -5 -86 -22 -28 -21 -28 -22 -22 -113 10 -178 89 -354 220 -491 224 -237 561 -316 860 -204 33 12 62 18 67 14 4 -5 20 -30 35 -56 l27 -48 -49 -21 c-123 -52 -189 -64 -363 -63 -150 0 -173 3 -253 27 -257 79 -463 249 -577 477 -65 131 -100 265 -100 386 0 88 -16 106 -107 113 l-71 6 117 202 c64 112 119 203 123 203 3 0 58 -90 122 -201z"/>
        </g>
      </svg>
    </span>
  );
};

export default Endorsement;
